
















































































































































.examinationHallDataTs {
  color: red;
}
